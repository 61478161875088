@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  color: #252525;
  font-family: 'Open Sans';
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
}

// KEYFRAMES

@keyframes fade {
  to {
    opacity: 1;
    transform: translate(0);
  }
}

// AOS

[data-aos="fadeDown"] {
  transform: translateY(-3rem);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-aos="fadeLeft"] {
  transform: translateX(3rem);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

[data-aos="fadeRight"] {
  transform: translateX(-3rem);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

[data-aos="fadeUp"] {
  transform: translateY(3rem);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}
